import React from 'react'
import Helmet from 'react-helmet'
import IconButton from '@material-ui/core/IconButton'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'
import Button from '@material-ui/core/Button'
import MenuIcon from '@material-ui/icons/Menu'
import asyncComponent from '../AsyncComponent'
import whiteLogo from '../../images/white_logo.svg'
import './index.scss'

const AccountMenu = asyncComponent(() => {
  return import('../AccountMenu')
})
export default class AppBar extends React.Component {
  state = {
    signInHover: false,
    anchorEl: null,
  }

  openUserMenu = event => {
    this.setState({ anchorEl: event.currentTarget })
  }

  closeUserMenu = () => {
    this.setState({ anchorEl: null })
  }

  componentDidMount() {
    const SmoothScroll = require('smooth-scroll')
    SmoothScroll('.nav-links', { header: '.nav' })
  }
  render() {
    const { anchorEl } = this.state
    const open = Boolean(anchorEl)
    const menuLinks = [
      { href: '#features', text: 'FEATURES' },
      { href: '#pricing', text: 'PRICING' },
      { href: '#referral', text: 'REFERRAL' },
    ]
    return (
      <nav className={`nav ${this.props.fluid ? 'fluid' : ''}`}>
        <Helmet>
          <link
            href="https://fonts.googleapis.com/css?family=Merriweather"
            rel="stylesheet"
            defer
          />
          <script
            type="text/javascript"
            src="https://cdn.ywxi.net/js/1.js"
            async
          />
        </Helmet>
        <div className="nav-wrapper">
          <a href="/">
            <img
              className="nav-bar-logo"
              src={whiteLogo}
              alt="Upload My Stock Logo"
            />
          </a>
          <div className="spacer" />

          {!this.props.fluid &&
            menuLinks.map(link => {
              return (
                <Button
                  key={link.href}
                  className={`nav-links`}
                  href={link.href}
                  data-scroll
                >
                  {link.text}
                </Button>
              )
            })}

          {this.state.signInHover || this.props.fluid ? (
            <AccountMenu openAuthOverlay={this.props.openAuthOverlay} />
          ) : (
            <Button
              className="signin-button"
              variant="contained"
              color="secondary"
              onMouseEnter={() => {
                this.setState({ signInHover: true })
              }}
            >
              SIGN IN
            </Button>
          )}
          <IconButton
            onClick={this.openUserMenu}
            className="mobile-menu-button"
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="mobile-menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={open}
            onClose={this.closeUserMenu}
          >
            {menuLinks.map(link => {
              return (
                <MenuItem key={link.href} href={link.href}>
                  {link.text}
                </MenuItem>
              )
            })}
          </Menu>
        </div>
      </nav>
    )
  }
}
